
import * as Sentry from '@sentry/vue'
import { Component, Prop } from 'vue-property-decorator'
import BISListForm from '@/components/bis_list/form.vue'
import BISListModify from '@/dataclasses/bis_list_modify'
import BISList from '@/interfaces/bis_list'
import NewBIS from './new_bis.vue'

@Component({
  components: {
    BISListForm,
  },
})
export default class EditBIS extends NewBIS {
  // Flags and URLS
  listLoaded = false

  // Data
  @Prop()
  bisId!: number

  breadcrumb!: string

  @Prop()
  characterId!: number

  // Flag indicating if we're ready to display the page
  get loaded(): boolean {
    if (this.charLoaded && this.listLoaded) {
      document.title = `Edit ${this.bisList.display_name} - ${this.character.name} - Savage Aim`
      return true
    }
    return false
  }

  // URL for reading and writing
  get url(): string {
    return `/backend/api/character/${this.characterId}/bis_lists/${this.bisId}/`
  }

  // Load functions
  async getList(): Promise<void> {
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the list into an array of character interfaces and store them in the character data list
        const data = await response.json() as BISList
        this.bisList = BISListModify.buildEditVersion(data)
        this.updateBreadcrumb()
        this.listLoaded = true
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching BIS List.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  // Populate the data
  created(): void {
    this.load()
  }

  async load(): Promise<void> {
    this.getChar()
    this.getList()
  }

  updateBreadcrumb(): void {
    this.breadcrumb = this.bisList.display_name
  }

  async postSave(): Promise<void> {
    await this.getList()
    this.updateBreadcrumb()
  }
}
